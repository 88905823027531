
.container11 {
    width: 100%;
    padding: 20px;
  }
  
  .header11 {
    text-align: center;
    margin-bottom: 20px;
  }
  /* Header Styles */
  .header-container {
    position: relative;
    text-align: center;
  }
  
  .header-image {
    width: 100%;
    max-height: 200px;
    object-fit: cover;
  }
  
  .header-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }
  
  .header-title h1 {
    font-size: 3rem;
    margin: 0;
  }
  
  .header-title p {
    font-size: 1.5rem;
    margin: 0;
  }
  
  /* Optional: Add background overlay for better readability of text */
  .header-container::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Dark transparent overlay */
    z-index: 1;
  }
  
  .header-title {
    z-index: 2; /* Ensure the text is above the overlay */
  }
  /* About Section */
.about-section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
}

.about-image img {
  width: 1200px;
  border-radius: 8px;
  margin-right: 30px;
}

.about-content h2 {
  margin-bottom: 15px;
}

.learn-more-button {
  background-color: white;
  color: black;
  padding: 10px 20px;
  border: 1px solid lightgray;
  border-radius: 5px;
  cursor: pointer;
}

  .logo img {
    width: 150px;
    margin-bottom: 20px;
  }
  
  .content11 {
    text-align: center;
    margin: 0 auto;
    max-width: 1000px;
  }
  
  .info11 {
    margin-bottom: 40px;
    padding: 20px;
    text-align: left;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .info11 h3 {
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  .info12 h3 {
    color: white;
  }
  
  .info11 p {
    font-size: 16px;
  }
  
  .info12 {
    background-color: #519259;
    margin-bottom: 40px;
    padding: 30px;
    width: auto;
    text-align: left;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .methodology-grid {
    display: grid;
    background-color: #519259;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    width: 100%;
    text-align: center;
  }
  
  .methodology-item {
    background-color: #519259;
    padding: 20px;
    width: auto;
    border: 1px solid #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: white;
  }
  
  .methodology-item p {
    font-size: 14px;
  }
  
  .methodology-image {
    width: 80px;
    margin-bottom: 10px;
  }
 
   
  .buttons12 {
    margin-top: 10px;
  }
  
  .buttons12 button {
    padding: 12px 20px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    border-radius: 8px;
  }
  
  .buttons11 {
    margin-top: 30px;
  }
  
  .buttons11 button {
    padding: 12px 20px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    border-radius: 8px;
  }
  
  .footer11 {
    margin-top: 40px;
    text-align: center;
  }
  
  .disclaimer11 {
    font-size: 12px;
    color: gray;
  }
  
  .copyright11 {
    margin-top: 10px;
    font-size: 14px;
    color: #999;
  }
  