
.breadcrumb {
    font: .75em sans-serif;
    list-style: none;
}


.breadcrumb p {
    margin: 0;
}

.breadcrumb li {
    display: inline-block;
    /* margin-bottom: .2em; */
}

.breadcrumb li a {
    background-color: #D9D9D9;
    box-sizing: border-box;
    color: #fff;
    display: block;
    max-height: 2em;
    padding: .5em 1em .5em 1.5em;
    position: relative;
    text-decoration: none;
    transition: .25s;
}

.breadcrumb li a:before {
    border-top: 1em solid transparent;
    border-bottom: 1em solid transparent;
    border-left: 1em solid #fff;
    content: "";
    position: absolute;
    top: 0;
    right: -1em;
    z-index: 1;
}

.breadcrumb li a:after {
    border-top: 1em solid transparent;
    border-bottom: 1em solid transparent;
    border-left: 1em solid #D9D9D9;
    content: "";
    position: absolute;
    top: 0;
    right: -1em;
    transition: .25s;
    z-index: 1;
}

.breadcrumb li a:hover {
    background-color: #519259;
}

.breadcrumb li a:hover:after {
    border-left-color: #519259;
}

.breadcrumb li:nth-child(2) a {
    background-color: #519259;
    pointer-events: none;
}

.breadcrumb li:nth-child(2) a:after {
    border-left-color: #519259;
}