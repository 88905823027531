.navlink-text{
    color:#3F3F40
}
.navlink-text:hover{
    color:#519259
}
@media (max-width: 640px) {
    .isVisible {
      display: none;
    }
    
}  